define(['app', 'siteObj', '$location'], (app, siteObj, $location) => {



  const urlSharing = () => {

    const component = {};
    component.app = app;
    component.location =$location;

    component.config = {
      selectors: {
        encodedMessage: '[data-js="widgetPolaris_message"]',
        socialButtons: '[data-js="widgetPolarisSharing_socialButton"]',
        copyButton: '[data-js="widgetPolarisSharing_copyButton"]',
        copyArea: '[data-js="widgetPolaris_textArea"]',
        copyText: '[data-js-element=copyText]'
      },
      data: {
        shareOption: 'data-share-option'
      },
      pageUrl: component.location.href,
      facebookAppId: siteObj.social.facebook.appid,
      siteRedirectUrl: siteObj.siteNonSecureURL
    };

    component.init = element => {
      component.element = element;
      component.encodedMessage =  encodeURIComponent(component.element.querySelector(component.config.selectors.encodedMessage).innerText);
      component.socialButtons = component.element.querySelectorAll(component.config.selectors.socialButtons);
      component.copyButton = component.element.querySelector(component.config.selectors.copyButton);
      component.copyArea = component.element.querySelector(component.config.selectors.copyArea);
      component.encodedLink = component.removeUtm();
      component.insertShareUrl();
      component.os = component.identifyMobileOS();
      component.copyButtonInit();
      component.initSocialButtons();
      component.addListeners();
    };

    component.insertShareUrl = () => {
      component.copyArea.innerText = component.config.pageUrl;
    };

    component.removeUtm = () => {
      let utmIndex = component.config.pageUrl.indexOf('?utm');
      return utmIndex > -1 ? component.config.pageUrl.slice(0,utmIndex) : component.config.pageUrl;
    };

    component.copyText = () => {
      const copyText = app.utils.getProperty('copySuccessfully', 'urlSharing');
      const coppiedSuccess = component.element.querySelector(component.config.selectors.copyText);
      coppiedSuccess.textContent = copyText;
    };

    component.copyButtonInit = () => {
      component.copyButton.addEventListener('click', () => {
        app.element.removeClass('hidden', component.copyArea);
        component.copyArea.select();
        document.execCommand('copy');
        component.copyText();
        app.element.addClass('success', component.copyButton.parentNode);
        app.element.addClass('hidden', component.copyArea);

        const notificationEl = document.querySelector(component.config.selectors.copyText);
        notificationEl.className = 'pollaris_copyText';
      });
    };

    component.initSocialButtons = () => {
      for (let i = 0, l = component.socialButtons.length; i < l; i++) {
        component.addSocialLink(component.socialButtons[i]);
      }
    };

    component.identifyMobileOS = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera.toLowerCase;

      if (userAgent.indexOf('ipad' || 'iphone') > -1) {
        return 'iOS';
      } else if (userAgent.indexOf('android') > -1) {
        return 'Android';
      } else {
        return 'unknown';
      }
    };

    component.utmValue = (channel) => {
      return `&utm_source=${channel}&utm_medium=social_share`;
    };

    component.addSocialLink = button => {

      const socialChannel = button.getAttribute(component.config.data.shareOption);
      let aHref;
      const smsEncodedBody = encodeURIComponent(`${component.encodedMessage} - ${component.encodedLink}`);

      switch (socialChannel) {
        case 'facebook':
          aHref = `https://www.facebook.com/dialog/feed?app_id=${component.config.facebookAppId}&display=popup&caption=${component.encodedMessage}&link=${component.encodedLink}`;
          break;

        case 'fb_messenger':
          aHref = `fb-messenger://share?link=${component.encodedLink}?`;
          break;

        case 'twitter':
          aHref = `https://twitter.com/intent/tweet?text=${component.encodedMessage} ${component.encodedLink}`;
          break;

        case 'email':
          aHref = `mailto:?subject=${document.title}&body=${component.encodedLink}`;
          break;

        case 'googlePlus':
          aHref = `https://plus.google.com/share?url=${component.encodedLink}`;
          break;

        case 'weibo':
          aHref = `http://service.weibo.com/share/share.php?url=${component.encodedLink}&appkey=&title=${component.encodedMessage}`;
          break;

        case 'vk':
          aHref = `http://vk.com/share.php?url=${component.encodedLink}`;
          break;

        case 'whatsapp':
          aHref = `whatsapp://send?text=${component.encodedMessage} - ${component.encodedLink}`;
          break;

        case 'sms':
          aHref = component.os === 'ios'? `sms:&body=${component.encodedLink}` : `sms:?body=${smsEncodedBody}`;
          break;
      }

      button.href = `${aHref}${component.utmValue(socialChannel)}`;
    };

    component.addListeners = () => {

      component.element.addEventListener('click', (el) => {
        component.trackSocialShare(el);
      });
    };

    component.socialChannel = el => {
      const target = el.target;

      if (target.getAttribute(component.config.data.shareOption) !== '' && target.hasAttribute(component.config.data.shareOption)) {
        return target.getAttribute(component.config.data.shareOption);
      } else if (target.parentElement.hasAttribute(component.config.data.shareOption) && target.parentElement.getAttribute(component.config.data.shareOption) !== '') {
        return target.parentElement.getAttribute(component.config.data.shareOption);
      } else {
        return null;
      }
    };

    component.trackSocialShare = el => {
      window.dataLayer.push({
        event: 'elysiumEvent',
        eventData: {
          eventCategory: 'Social Share',
          eventAction: 'Share Clicked',
          eventLabel: '"' + component.socialChannel(el) + '"',
          eventPage: '"' + window.location.href + '"'
        }
      });
    };

    return component;

  };

  return urlSharing;
});
